import type { TFunctionReturn } from "@/modules/i18n/components/types"
import type { SimpleSelectOptions, TriggeredAction } from "@/modules/uiKitOverrides/SelectFilter/Select"
// eslint-disable-next-line no-restricted-imports
import type { SelectProps } from "@jobteaser/ui-kit/components/SelectFilter/Select/Select"
import type { FunctionComponent } from "react"

import { clsx } from "clsx"
import { usePathname } from "next/navigation"
import { useId } from "react"

// eslint-disable-next-line no-restricted-imports
import { Filter as FilterUiKit } from "@jobteaser/ui-kit/components/SelectFilter/Filter/Filter"

import { useTranslation } from "@/modules/i18n/components/useTranslation"
import { isUiShiftCompatibleWithPathname } from "@/modules/layouts/helpers/isUiShiftCompatibleWithPathname"

import styles from "./Select.module.css"

type ConditionalProps =
  | {
      isMulti: true
      onApply?: (value: SimpleSelectOptions[]) => void
      onOutsideClick?: (value: SimpleSelectOptions[]) => void
      onChange?: (value: SimpleSelectOptions[], triggeredAction: TriggeredAction) => void
    }
  | {
      isMulti: false
      onApply?: (value: SimpleSelectOptions) => void
      onOutsideClick?: (value: SimpleSelectOptions) => void
      onChange?: (value: SimpleSelectOptions, triggeredAction: TriggeredAction) => void
    }

export type FilterProps = Omit<SelectProps, "isMulti"> & {
  applyLabel?: TFunctionReturn | string
  clearLabel?: TFunctionReturn | string
  selectAllLabel?: string
  indicatorClearLabel?: string
  isCheckbox?: boolean
  isCheckboxOnGroupHeading?: boolean
  isSearchable?: boolean
} & ConditionalProps &
  Record<string, unknown> // mainly some react-select props : https://react-select.com/props#prop-types
export const Filter: FunctionComponent<FilterProps> = props => {
  const { t } = useTranslation(["shared_select_input"])
  const pathname = usePathname()
  const isUiShiftCompatible = isUiShiftCompatibleWithPathname(pathname)

  const { applyLabel, clearLabel, className, indicatorClearLabel, id, ...restProps } = props
  const uniqueId = useId()
  const uniqueIdToUse = id || uniqueId
  const classNames = clsx(styles.select, className as string)

  const additionalProps = {
    applyLabel: applyLabel ?? t("shared_select_input.apply"),
    clearLabel: clearLabel ?? t("shared_select_input.clear"),
    customComponents: isUiShiftCompatible
      ? {
          DropdownIndicator: () => null,
        }
      : {},
    indicatorClearLabel: indicatorClearLabel ?? t("shared_select_input.clear"),
  }

  return (
    <FilterUiKit
      className={classNames}
      {...restProps}
      id={uniqueIdToUse}
      instanceId={uniqueIdToUse}
      {...additionalProps}
    />
  )
}
