import type { NextRouter } from "next/router"

// The usage of next/router is explicitly needed here.
// eslint-disable-next-line no-restricted-imports
import { useRouter as useNextRouter } from "next/router"

export type useRouter = () => NextRouter

export function useRouter(): Pick<NextRouter, "back" | "forward" | "push" | "reload" | "replace" | "prefetch"> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { back, forward, prefetch, push, reload, replace } = useNextRouter()

  return { back, forward, prefetch, push, reload, replace }
}
